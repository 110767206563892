
//LatamSans regular
@font-face {
  font-family: "Latam Sans";
  src: url('/fonts/latam/LatamSans-Regular.ttf');
}

//LatamSans Black
@font-face {
  font-family: "Latam Sans";
  src: url('/fonts/latam/LatamSans-Black.ttf');
  font-weight: 900;
}

//LatamSans Bold
@font-face {
  font-family: "Latam Sans";
  src: url('/fonts/latam/LatamSans-Bold.ttf');
  font-weight: bold;
}

//SpaceMono Bold-italic
@font-face {
  font-family: "Space Mono";
  src: url('/fonts/latam/SpaceMono-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}




