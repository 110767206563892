@import "../global/fonts";
@import "../global/bootstrap";
@import "../global/variables";
$--color-danger: #e8114b;
@import "~element-ui/packages/theme-chalk/src/index";

html {
  height: 100%;
  background-color: #f3f2f6;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Latam Sans", sans-serif;
  color: #333333;
}

.block {
  width: 100% !important;
  display: block !important;
}

.custom-popper-class {
  width: 383px !important;
}

.no-left-gutter {
  padding-left: 0 !important;
}

.no-right-gutter {
  padding-right: 0 !important;
}

.close-button {
  cursor: pointer;
}

.search-button {
  margin: 33px 0 43px 0;
  height: 60px;
  width: 100%;
  max-width: 340px;
  display: inline-block;
  border-radius: 4px;
  background-color: $strong-pink-color;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.5);
  padding: 19px 81px 19px 81px;
  color: #ffffff;
  font-family: "Latam Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  &.disabled {
    background: gray;
  }

  &.search-button-xs {
    padding: 10px 30px 10px 30px;
    font-size: 18px;
    background-color: #e9124c;
    font-weight: normal;
    height: inherit;
    display: block;
    width: fit-content;
    border-radius: 2px;
  }
}

.center-in-parent {
  transform: translate(0, 20%);
}

.latam-navbar {
  background-color: $latam-navbar-background-color;
  color: $latam-navbar-text-color;
  box-shadow: 0 0 66px 4px rgba(63, 63, 63, 0.5);
  position: relative;
  z-index: 10;
  .main-row {
    min-height: 84px;
    padding: 22px 0;
  }
  .btn-login {
    color: #ffffff;
    font-family: "Latam Sans", sans-serif;
    font-size: 1.13rem;
    font-weight: bold;
    background-color: #ed1650;
    border-color: #ed1650;
    border-radius: 6px;
    line-height: 1;
    padding: 10px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .hello-user {
    text-transform: capitalize;
    width: 100%;
    padding: 0 30px;
    margin: 0;
  }
  .latam-logo {
    margin-right: 15px;
  }
}

.latam-home-page {
  .background-image {
    padding: 0;
    background: url("/images/bg-main.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto;
    @include media-breakpoint-up(lg) {
      background-size: 100%;
    }
  }
  .main-title {
    padding-top: 60px;
    color: #ffffff;
    font-family: "Latam Sans", sans-serif;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    //text-shadow: 0 1px 2px rgba(0,0,0,0.92);
  }

  .main-subtitle {
    padding-top: 17px;
    color: #ffffff;
    font-family: "Latam Sans", sans-serif;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    //text-shadow: 0 1px 2px rgba(0,0,0,0.92);
  }

  .search-box-container {
    padding-top: 50px;
    .logo {
      margin-top: 24px;
      padding-right: 7px;
      width: 128px;
    }
    .search-box {
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      padding: 0 20px;
      @include media-breakpoint-up(md) {
        padding: 0 30px;
      }
      .el-input {
        font-size: 16px;
      }
      .arrow-upward-material {
        height: 4px;
        width: 4px;
        transform: rotate(-315deg);
      }
      .arrow-downward-material {
        height: 4px;
        width: 4px;
        transform: rotate(-225deg);
      }
    }
    .buscar-autos-en-alquiler {
      color: #1b0088;
      font-family: "Latam Sans", sans-serif;
      font-size: 25px;
      margin-top: 30px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }
    .column-title {
      color: $normal-text-color;
      font-family: "Latam Sans", sans-serif;
      font-size: 20px;
      font-weight: 900;
      line-height: 25px;
    }
  }

  .brands-section {
    margin-top: 46px;
  }

  .section-title {
    color: $normal-text-color;
    font-family: "Latam Sans", sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-top: 0;
  }

  .section-description {
    color: $normal-text-color;
    font-family: "Latam Sans", sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
  }

  .info-container {
    background-color: $latam-footer-background-color;
    vertical-align: top;
    padding: 40px 0;
    .info-card {
      padding: 30px 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 4px;
      position: relative;
      height: auto;
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        height: 100%;
      }
      .circle-icon-container {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        top: -27px;
        left: 43%;
        background-color: $latam-footer-background-color;
        div {
          width: 35px;
          height: 35px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 50%;
          background-color: #ffffff;
          color: $latam-footer-background-color;
        }
      }
      h4 {
        font-weight: bold;
        font-family: "Latam Sans", sans-serif;
        text-align: center;
        color: $normal-text-color;
      }
      p,
      li {
        font-family: "Latam Sans", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $normal-text-color;
      }
      li {
        margin-bottom: 10px;
        padding-left: 20px;
        text-align: left;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }
  }
  .custom-card {
    border: 1px solid black;
  }
}

.latam-footer {
  background-color: $latam-footer-background-color;
  width: 100%;
  height: 88px;
}

.current-search-data {
  background-color: $strong-blue-color;
  padding: 30px 0;
  color: #fff;
  box-shadow: 0 0 26px 4px rgba(63, 63, 63, 0.5);
  position: relative;
  z-index: 3000;
  h4 {
    font-weight: 800;
    font-size: 20px;
  }
  p {
    margin: 0;
    font-size: 18px;
    line-height: 1;
    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.change-search-button {
  padding: 15px 20px;
  line-height: 1;
  background-color: $strong-pink-color;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 0;
  .material-icons {
    vertical-align: middle;
  }
}

.gray-bg {
  position: fixed;
  z-index: -1;
  background-color: rgb(238, 238, 238);
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
}

.result-card-component {
  color: #333333;
  background: #ffffff;
  border: 1px solid $border-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
  .material-icons {
    font-size: 16px;
    vertical-align: middle;
  }
  .car-data-container {
    padding: 20px;
    border-right: 1px solid $border-color;
    text-align: center;
    height: 100%;
  }
  .car-main-info {
    padding: 20px;
    border-bottom: 1px solid $border-color;
    .title {
      font-weight: 800;
      font-size: 18px;
    }
    .subtitle {
      font-size: 16px;
    }
    .features {
      margin-top: 10px;
      .feature {
        font-size: 14px;
      }
      .material-icons {
        font-size: 16px;
        vertical-align: middle;
      }
      .heading {
        font-size: 10px;
        color: #333;
      }
      .feature.green {
        color: #86ab4a;
        .heading {
          color: #333;
        }
      }
    }
  }
  .route-info {
    padding: 20px;
    .location-data {
      font-size: 14px;
      .heading {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.28px;
        margin-bottom: 10px;
      }
    }
  }
  .pricing-rent-info {
    background-color: #f7f7f7;
    position: relative;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    .price-tag {
      padding: 15px;
      padding-top: 70px;
      justify-content: flex-end;
      text-align: right;
    }
    .buy-button {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      background-color: $latam-navbar-background-color;
      padding: 15px 50px;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    .featured-badge {
      background-color: $turquoise;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      padding: 3px 10px;
      border-radius: 4px 0 0 4px;
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 0;
    }
    .total-price {
      text-align: right;
      font-size: 24px;
      color: $strong-blue-color;
      font-weight: 800;
      .title {
        font-size: 16px;
        color: #333333;
        font-weight: normal;
      }
    }
    .price-by-day {
      text-align: right;
      font-size: 16px;
      font-weight: normal;
    }
  }
}

.search-filters {
  margin-top: 50px;
  h5 {
    font-weight: bold;
    font-size: 18px;
    margin: 0;
  }
  hr {
    margin: 10px 0;
    color: #cccccc;
  }
  .check-list {
    margin-bottom: 40px;
    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }
    .form-check-label {
      cursor: pointer;
    }
    .form-check {
      padding-left: 0;
      margin: 14px 0;
      label:before {
        content: "check_box_outline_blank";
        font-family: "Material Icons";
        font-size: 18px;
        color: #333333;
        vertical-align: middle;
        margin-right: 5px;
      }
      input[type="checkbox"]:checked + label,
      input[type="radio"]:checked + label {
        &:before {
          content: "check_box";
          color: $strong-blue-color;
        }
      }
    }
  }
}

.filter-badge {
  background: #e6e6e6;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #2721b5;
  font-size: 14px;
  margin-right: 5px;
  padding: 7px 15px;
  padding-right: 30px;
  position: relative;
  line-height: 0.9;
  .material-icons {
    font-size: 15px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.el-select-dropdown {
  .flight-material {
    font-size: 12px !important;
    color: $strong-pink-color;
  }
}

.results-container {
  background-color: #eeeeee;
}

.minicards-container {
  overflow: hidden;
  margin: 50px 0 30px 0;
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(100%, #eeeeee)
    );
  }
}

.minicards-track {
  overflow-x: auto;
  position: relative;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.minicard-component {
  min-width: 130px;
  display: inline-block;
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  box-shadow: 0 0 4px 0 #d3d3d3, inset 0px -5px 0px #e6e6e6;
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  margin: 0 2px;
  cursor: pointer;
  &.active {
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 #d3d3d3, inset 0px -5px 0px $strong-blue-color;
  }
  h4 {
    font-weight: bold;
    font-size: 14px;
  }
  .car-photo {
    height: auto;
    width: 100px;
    mix-blend-mode: multiply;
    display: block;
  }
  .info {
    font-size: 0;
  }
  .general-info {
    display: inline-block;
    width: 33.333%;
    font-size: 14px;
    .material-icons {
      font-size: 16px;
      vertical-align: middle;
    }
  }
}

.media-logout {
  padding: 4px;
  border-radius: 10px;
  background-color: $strong-pink-color;
}

.good-choice {
  background-color: rgba(0, 181, 173, 0.08);
  border-radius: 0 4px 4px 0;
  padding: 15px;
  border-left: 4px solid $turquoise;
  .title {
    font-weight: bold;
    font-size: 18px;
    color: $turquoise;
  }
  .sidebar-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      font-size: 14px;
      position: relative;
      padding-left: 18px;
      margin-top: 5px;
      &:before {
        content: "check_circle";
        font-family: "Material Icons";
        font-size: 14px;
        vertical-align: middle;
        color: $turquoise;
        position: absolute;
        left: 0;
      }
    }
  }
}

.insufficient-miles {
  @extend .good-choice;
  background-color: rgba(237, 22, 80, 0.1);
  border-left: 4px solid $strong-pink-color;
  .title {
    color: $strong-pink-color;
  }
  .sidebar-list {
    li {
      &:before {
        content: "error";
        color: $strong-pink-color;
      }
    }
  }
}

.canceled-reservation {
  background-color: rgba(237, 22, 80, 0.1);
  border-left: 4px solid $strong-pink-color;
  color: $strong-pink-color;
  font-weight: bold;
  padding-left: 13px;
}

.active-reservation {
  background-color: #ebf9f9;
  border-left: 4px solid #00b5ad;
  color: #00b5ad;
  font-weight: bold;
  padding-left: 13px;
}

.expired-reservation {
  background-color: #f7f7f7;
  border-left: 4px solid #cccccc;
  color: #cccccc;
  font-weight: bold;
  padding-left: 13px;
}

.d-flex {
  display: flex;
}
.d-flex > div {
  float: none;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.navbar-pos {
  display: flex;
  justify-content: space-between !important;
  height: 100%;
}

.idnumber {
  color: #2d34ce;
}

.detail-button {
  color: $latam-navbar-background-color;
  background-color: #fff;
  padding: 15px 25px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.idnumber:link  {
  color: #2d34ce !important;
}

.idnumber:visited {
  color: #2d34ce !important;
}

.maintenance-banner {
  background-color: #eeeeee;
  padding: 20px;
  div {
    background-color: #fef8e5;
    color: #303030;
    padding: 15px;
    text-align: center;
  }
}
